<template>
  <div class="totalPr">
    <div v-if="showTask == 'taskList'" class="container">
      <div class="flex_between bbe9s" style="width: 100%">
        <div class="f-left titleP">待处理任务</div>
      </div>
      <div class="flex_warp mt10 pb20">
        <!-- 订单时间 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20">订单创建时间</span>
          <el-date-picker
            v-model="time1"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <!-- 城市筛选 -->
        <div class="blocks2 flex_start flex_items">
          <span class="mr20">城市筛选</span>
          <el-select
            v-model="citykind"
            placeholder="选择城市"
            style="width: 127px"
            :clearable="true"
            @change="getwhere(citykind)"
            class="littleBox"
          >
            <el-option
              v-for="(item, index) in citys"
              :key="index"
              :label="item.city"
              :value="item.city_id"
            ></el-option>
          </el-select>
        </div>
        <!-- 是否加微信 -->
        <div class="blocks2 flex_start flex_items">
          <span class="mr10">是否添加微信</span>
          <el-select
            v-model="wechat"
            placeholder="微信添加状态"
            style="width: 138px"
            :clearable="true"
            class="littleBox"
          >
            <el-option label="是" value="1"></el-option>
            <el-option label="否" value="0"></el-option>
          </el-select>
        </div>
        <!-- 是否精装房 -->
        <div class="blocks2 flex_start flex_items">
          <span class="mr10">是否精装房</span>
          <el-select
            v-model="hardbound"
            placeholder="是否精装房"
            style="width: 138px"
            :clearable="true"
            class="littleBox"
          >
            <el-option label="是" :value="1"></el-option>
            <el-option label="否" :value="0"></el-option>
          </el-select>
        </div>
        <!-- 任务类型 -->
        <div class="blocks2 flex_start flex_items">
          <span class="mr20">任务类型</span>
          <el-select
            v-model="complaint"
            placeholder="选择任务类型"
            :clearable="true"
            style="width: 138px"
            filterable
            class="littleBox"
          >
            <el-option
              v-for="(item, index) in taskTypes"
              :key="index"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <!-- 录入人员 -->
        <div class="blocks2 flex_start flex_items">
          <span class="mr20">录入客服</span>
          <el-select
            v-model="client"
            placeholder="选择录入客服"
            :clearable="true"
            style="width: 146px"
            filterable
            class="littleBox"
          >
            <el-option
              v-for="(item, index) in clients"
              :key="index"
              :label="item.username"
              :value="item.admin_id"
            ></el-option>
          </el-select>
        </div>
        <!-- 接单人 -->
        <!-- <div v-if="citykind != ''" class="blocks2 flex_items flex_start">
        <span class="mr20">接单人员</span>
        <el-select
          class="mr10 littleBox"
          filterable
          v-model="shopper"
          collapse-tags
          placeholder="选择接单人员"
          :clearable="true"
        >
          <el-option
            v-for="item in shoppers"
            :key="item.user_id"
            :label="item.usernamestorename"
            :value="item.usernamestorename"
          ></el-option>
        </el-select>
      </div> -->
        <!-- 任务生成时间 -->
        <div class="blocks2 flex_items flex_start">
          <span class="mr20">任务生成时间</span>
          <el-date-picker
            v-model="taskTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            class="datePicker"
            :default-time="['00:00:00', '23:59:59']"
          ></el-date-picker>
        </div>
        <!-- 筛选订单 -->
        <div class="blocks2 flex_items">
          <el-input
            placeholder="请输入内容"
            v-model="chosecon"
            class="input-with-select littleBox"
          >
            <el-select
              v-model="selectkind"
              slot="prepend"
              :clearable="true"
              placeholder="请选择类别"
              style="width: 120px"
              class="littleBox"
            >
              <el-option label="联系人" value="contacts"></el-option>
              <el-option label="联系方式" value="telephone"></el-option>
              <el-option label="订单号" value="order_no"></el-option>
              <el-option label="维修地址" value="addres"></el-option>
            </el-select>
          </el-input>
        </div>
        <!-- 筛选 -->
        <div class="blocks2">
          <el-button
            type="primary"
            size="small"
            plain
            style="margin-right: 30px"
            @click="getDataAgain"
            >筛选</el-button
          >
          <el-button type="warning" plain size="small" @click="addOrder"
            >创建订单</el-button
          >
        </div>
      </div>

      <div class="mt10" style="width: 100%">
        <el-table :data="data" border :header-cell-style="{ color: '#252525' }">
          <el-table-column prop="cc_id" label="任务编号"></el-table-column>
          <el-table-column
            prop="manage_time"
            label="任务生成时间"
          ></el-table-column>
          <el-table-column prop="order_no" label="订单号"></el-table-column>
          <el-table-column prop="contacts" label="客户姓名"></el-table-column>
          <el-table-column prop="telephone" label="联系电话"></el-table-column>
          <el-table-column prop="addres" label="维修地址"></el-table-column>
          <el-table-column prop="states" label="订单状态"></el-table-column>
          <el-table-column prop="store_name" label="店铺"></el-table-column>
          <el-table-column prop="username" label="店长姓名"></el-table-column>
          <el-table-column
            prop="channel_title"
            label="订单创建渠道"
          ></el-table-column>
          <el-table-column label="任务类型">
            <template slot-scope="scope">
              <span v-if="scope.row.type == 0">指派任务</span>
              <span v-if="scope.row.type == 1">超时未接单</span>
              <span v-if="scope.row.type == 2">打卡未报价</span>
              <span v-if="scope.row.type == 3">施工超期</span>
              <span v-if="scope.row.type == 4">完工回访</span>
              <span v-if="scope.row.type == 5">差评与投诉</span>
            </template>
          </el-table-column>
          <el-table-column label="添加微信">
            <template slot-scope="scope">
              <span v-if="scope.row.wechat == 0">否</span>
              <span v-else>是</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="admin_ids"
            label="任务操作人"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <div>
                <el-button
                  size="small"
                  plain
                  type="primary"
                  @click="dealTask(scope.row)"
                  >处理任务</el-button
                >
              </div>
              <div style="padding-left: -5px; margin-top: 5px">
                <el-button
                  size="small"
                  plain
                  type="warning"
                  @click="lookTask(scope.row)"
                  >查看订单</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- <avue-crud :option="option" :data="data" @search-change="searchChange">
        <template slot-scope="scope" slot="type">
          <span v-if="scope.row.type == 0">指派任务</span>
          <span v-if="scope.row.type == 1">超时未接单</span>
          <span v-if="scope.row.type == 2">打卡未报价</span>
          <span v-if="scope.row.type == 3">施工超期</span>
          <span v-if="scope.row.type == 4">完工回访</span>
          <span v-if="scope.row.type == 5">差评与投诉</span>
        </template>
        <template slot-scope="scope" slot="wechat">
          <span v-if="scope.row.wechat == 0">否</span>
          <span v-else>是</span>
        </template>
        <template slot-scope="{ row, type, size }" slot="menu">
          <div>
            <el-button :size="size" :type="type" @click="dealTask(row)"
              >处理任务</el-button
            >
          </div>
          <div style="padding-left:-5px;">
            <el-button :size="size" :type="type" @click="lookTask(row)"
              >查看订单</el-button
            >
          </div>
        </template>
      </avue-crud> -->
      <div class="flex_start mt20 flex_items">
        <span class="demonstration mr20" style="display: inline-block">
          总共
          <span style="color: red">{{ count }}</span
          >条数据,当前 <span style="color: red">{{ data.length }}</span
          >条
        </span>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="20"
          :total="count"
          layout="prev, pager, next, jumper"
        ></el-pagination>
      </div>
      <!-- 创建订单 -->
      <el-dialog
        :visible.sync="dialogTableVisibleadd"
        width="80%"
        :close-on-click-modal="false"
        @close="diagclose()"
        :key="keyNum"
      >
        <Add2
          v-if="dialogname == 'addOrder'"
          :show.sync="dialogTableVisibleadd"
        ></Add2>
        <AssignOrder
          v-if="dialogname == 'AssignOrder'"
          @refreshEvent="getDataAgain"
        ></AssignOrder>
        <Detailes2
          v-if="dialogname == 'chakan'"
          @refreshEvent="getDataAgain"
        ></Detailes2>
      </el-dialog>
    </div>
    <div v-if="showTask == 'taskDetail'" class="box">
      <div
        style="
          width: 96%;
          background: white !important;
          height: 100% !important;
          margin-bottom: 15%;
        "
      >
        <div @click="returnTaskList" style="cursor: pointer; color: #409eff">
          <i class="el-icon-arrow-left"></i>返回列表
        </div>
        <orderOvertime
          v-if="taskShow == 1"
          :parentRow="parentRow"
        ></orderOvertime>
        <clockNoQuote
          v-if="taskShow == 2"
          :parentRow="parentRow"
        ></clockNoQuote>
        <buildOvertime
          v-if="taskShow == 3"
          :parentRow="parentRow"
        ></buildOvertime>
        <finishedVisit
          v-if="taskShow == 4"
          :parentRow="parentRow"
        ></finishedVisit>
        <complaints v-if="taskShow == 5" :parentRow="parentRow"></complaints>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      keyNum: 1,
      showTask: "taskList",
      taskShow: 1,
      isheight: "",
      time1: "",
      taskTime: "",
      citykind: "",
      client: "",
      clients: [],
      citys: [],
      wechat: "",
      hardbound: "",
      currentPage: 1,
      chosecon: "",
      selectkind: "",
      count: 0,
      shengid: "",
      complaint: "",
      data: [],
      items: [],
      data: [],
      dialogname: "",
      option: {
        border: true,
        size: "medium",
        page: false,
        dateBtn: false,
        dateDefault: false,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        columnBtn: false, //列显隐按钮
        refreshBtn: false, //刷新按钮
        column: [
          {
            label: "任务编号",
            prop: "cc_id",
          },
          {
            label: "挂起状态",
            prop: "hang_up",
            hide: true,
          },
          {
            label: "订单编号",
            prop: "order_id",
            hide: true,
          },
          {
            label: "任务生成时间",
            prop: "manage_time",
          },
          {
            label: "订单号",
            prop: "order_no",
          },
          {
            label: "客户姓名",
            prop: "contacts",
          },
          {
            label: "联系电话",
            prop: "telephone",
          },
          {
            label: "维修地址",
            prop: "addres",
          },
          {
            label: "订单状态",
            prop: "states",
          },
          {
            label: "店铺",
            prop: "store_name",
          },
          {
            label: "店长姓名",
            prop: "username",
          },
          {
            label: "订单创建渠道",
            prop: "channel_title",
          },
          {
            label: "任务类型",
            prop: "type",
            slot: true,
          },
          {
            label: "添加微信",
            prop: "wechat",
            slot: true,
          },
          {
            label: "任务操作人",
            prop: "admin_ids",
          },
        ],
      },
      //任务详细信息
      taskDetail: {
        name: "张三",
        status: "一个状态",
        order_id: 202107141422,
        store: "金沙店",
        storeKeeper: "李华",
        createdTime: "1994-02-23 00:00:00",
        phone: 15182996821,
        orderFrom: "推客宝盒",
        houseProblem: "墙面翻新",
        addWechat: "是",
        addr: "四川省成都市金牛区万通金融广场西塔",
        remark: "这是一个备注呢，多写几个字",
      },
      //任务类型
      taskTypes: [],
      finishDialog1: false,
      radio1: 2,
      shopper: "",
      shoppers: [],
      textarea1: "",
      finishDialog2: false,
      orderTime: "",
      form1: {
        orderTime: "",
        reason: "",
        remark: "",
      },
      formLabelWidth: "100px",
      form2: {
        customer: "",
      },
      finishDialog3: false,
      parentRow: {},
      dialogTableVisibleadd: false,
    };
  },
  mounted() {
    this.getcitys();
    this.getclient();
    this.getPendList();
    this.getTaskKind();
  },
  methods: {
    resetHeightHandler() {
      let t = this;
      window.addEventListener("resize", function () {
        t.resetHeight();
      });
      t.resetHeight();
    },
    resetHeight() {
      let windowHeight = document.body.clientHeight;
      let header =
        document.getElementsByClassName("avue-header")[0].clientHeight;
      let avueTags =
        document.getElementsByClassName("avue-tags")[0].clientHeight;
      let search =
        document.getElementsByClassName("avue-crud-search")[0].clientHeight;
      this.option.height = windowHeight - header - avueTags - search - 160;
      this.visible = false;
      this.$nextTick(() => {
        this.visible = true;
      });
    },
    //获取任务类型
    getTaskKind() {
      this.util.get(this.HOST + "/Common/service", {}).then((res) => {
        if (res.code == 200) {
          this.taskTypes = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    getPendList() {
      this.$loading(this.util.opload);
      if (this.time1 == null || this.time1 == "") {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      if (this.taskTime == null || this.taskTime == "") {
        this.taskTime = "";
      }
      let time3 = this.util.datatime(this.taskTime[0]);
      let time4 = this.util.datatime(this.taskTime[1]);
      if (time3 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time3 = "";
        time4 = "";
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          start_time: time1,
          end_time: time2,
          cc_start_time: time3,
          cc_end_time: time4,
          city_id: this.citykind,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: this.client,
          store_id: this.store,
          assignor: this.shopper,
          complaints: this.complaint,
          page: this.currentPage,
          wechat: this.wechat,
          hardbound: this.hardbound,
          type: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].titles2) {
                res.data.data[j].channel_title =
                  res.data.data[j].channel_title +
                  "-" +
                  res.data.data[j].titles2;
              } else {
                res.data.data[j].channel_title = res.data.data[j].channel_title;
              }
            }
            this.data = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    searchChange(params, done) {
      done();
      this.$message.success(JSON.stringify(params));
    },
    getcitys() {
      // 城市
      this.util.get(this.HOST + "/Common/cheng", {}).then((res) => {
        if (res.code == 200) {
          this.citys = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    // 筛选城市之后请求店长
    getwhere(citykind) {
      //console.log(citykind, "111");
      this.shopper = "";
      this.order_id = "";
      if (citykind) {
        this.citys.map((item) => {
          if (citykind == item.city_id) {
            this.shengid = item.province_id;
          }
        });
        this.shop();
      }
    },
    shop() {
      this.util
        .get(this.HOST + "/Common/user", {
          city_id: this.citykind,
        })
        .then((res) => {
          if (res.code == 200) {
            this.shoppers = res.data;
          } else {
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getclient() {
      // 获取客服
      this.util.get(this.HOST + "/Common/CustomerService", {}).then((res) => {
        if (res.code == 200) {
          this.clients = res.data;
        } else {
          this.$message({
            showClose: true,
            message: res.msg,
            type: "error",
            duration: 1200,
            offset: 90,
          });
        }
      });
    },
    handleCurrentChange(val) {
      //页数改变之后的请求数据
      this.$loading(this.util.opload);
      if (this.time1 == null || this.time1 == "") {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      if (this.taskTime == null || this.taskTime == "") {
        this.taskTime = "";
      }
      let time3 = this.util.datatime(this.taskTime[0]);
      let time4 = this.util.datatime(this.taskTime[1]);
      if (time3 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time3 = "";
        time4 = "";
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          start_time: time1,
          end_time: time2,
          cc_start_time: time3,
          cc_end_time: time4,
          city_id: this.citykind,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: this.client,
          store_id: this.store,
          assignor: this.shopper,
          complaints: this.complaint,
          page: this.currentPage,
          wechat: this.wechat,
          hardbound: this.hardbound,
          type: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].titles2) {
                res.data.data[j].channel_title =
                  res.data.data[j].channel_title +
                  "-" +
                  res.data.data[j].titles2;
              } else {
                res.data.data[j].channel_title = res.data.data[j].channel_title;
              }
            }
            this.data = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    getDataAgain() {
      this.$loading(this.util.opload);
      if (this.time1 == null) {
        this.time1 = "";
      }
      let time1 = this.util.datatime(this.time1[0]);
      let time2 = this.util.datatime(this.time1[1]);
      if (time1 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time1 = "";
        time2 = "";
      }
      if (this.taskTime == null || this.taskTime == "") {
        this.taskTime = "";
      }
      let time3 = this.util.datatime(this.taskTime[0]);
      let time4 = this.util.datatime(this.taskTime[1]);
      if (time3 == "NaN-NaN-NaN NaN:NaN:NaN") {
        time3 = "";
        time4 = "";
      }
      this.loading = true;
      this.data = [];
      this.currentPage = 1;
      if (this.chosecon == "") {
        this.selectkind = "";
      }
      this.util
        .get(this.HOST + "/Cc/Cc", {
          start_time: time1,
          end_time: time2,
          cc_start_time: time3,
          cc_end_time: time4,
          city_id: this.citykind,
          limit: 20,
          chen: this.selectkind,
          contacts: this.chosecon,
          admin_id: this.client,
          store_id: this.store,
          assignor: this.shopper,
          complaints: this.complaint,
          page: this.currentPage,
          wechat: this.wechat,
          hardbound: this.hardbound,
          type: 2,
        })
        .then((res) => {
          if (res.code == 200) {
            this.count = res.data.count;
            for (let j = 0; j < res.data.data.length; j++) {
              if (res.data.data[j].titles2) {
                res.data.data[j].channel_title =
                  res.data.data[j].channel_title +
                  "-" +
                  res.data.data[j].titles2;
              } else {
                res.data.data[j].channel_title = res.data.data[j].channel_title;
              }
            }
            this.data = res.data.data;
            this.$loading().close();
          } else {
            this.$loading().close();
            this.$message({
              showClose: true,
              message: res.msg,
              type: "error",
              duration: 1200,
              offset: 90,
            });
          }
        });
    },
    addOrder() {
      this.dialogname = "addOrder";
      this.dialogTableVisibleadd = true;
      // this.$store.commit("setDialog", true);
    },
    diagclose() {
      this.keyNum++;
      this.getDataAgain();
    },
    dealTask(row) {
      if (row.type == 1) {
        this.taskShow = 1;
        this.showTask = "taskDetail";
        this.parentRow = row;
      } else if (row.type == 2) {
        this.taskShow = 2;
        this.showTask = "taskDetail";
        this.parentRow = row;
      } else if (row.type == 3) {
        this.taskShow = 3;
        this.showTask = "taskDetail";
        this.parentRow = row;
      } else if (row.type == 5) {
        this.taskShow = 5;
        this.showTask = "taskDetail";
        this.parentRow = row;
      } else if (row.type == 4) {
        this.taskShow = 4;
        this.showTask = "taskDetail";
        this.parentRow = row;
      } else if (row.type == 0) {
        this.dialogname = "AssignOrder";
        this.dialogTableVisibleadd = true;
      }
      this.$store.commit("setOrder", row);
    },
    lookTask(row) {
      this.dialogname = "chakan";
      this.dialogTableVisibleadd = true;
      this.$store.commit("setOrder", row);
    },
    returnTaskList() {
      this.showTask = "taskList";
      this.getPendList();
    },
    openFinish() {
      this.finishDialog1 = true;
    },
    openSetting() {
      this.finishDialog2 = true;
    },
    openDeliver() {
      this.finishDialog3 = true;
    },
  },
  watch: {
    renderHeader: (h, { column }) => {
      let l = column.label.length;
      let f = 16;
      column.minWidth = f * (l + 2);
      return h("div", { class: "table-head", style: { width: "100%" } }, [
        column.label,
      ]);
    },
  },
  computed: {
    // 店长
    NewItems2() {
      var _this = this;
      var NewItems2 = [];
      this.shoppers.map(function (item) {
        if (item.usernamestorename.search(_this.shopper) != -1) {
          NewItems2.push(item);
        }
      });
      return NewItems2;
    },
  },
  components: {
    orderOvertime: () => import("@/views/pendTask/orderOvertime.vue"),
    clockNoQuote: () => import("@/views/pendTask/clockNoQuote.vue"),
    buildOvertime: () => import("@/views/pendTask/buildOvertime.vue"),
    complaints: () => import("@/views/pendTask/complaints.vue"),
    finishedVisit: () => import("@/views/pendTask/finishedVisit.vue"),
    Add2: () => import("@/components/addorder2.vue"),
    AssignOrder: () => import("@/components/AssignOrder.vue"),
    Detailes2: () => import("@/components/detailes2.vue"),
  },
};
</script>
<style>
.BBg {
  width: 100%;
  /* position: fixed; */
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
}
.totalPr {
  background: white !important;
  padding-left: 25px;
  width: 96% !important;
}
.container {
  margin: 0 auto;
  background: white;
  padding: 15px 0 0 10px;
  border-radius: 8px;
  /* height: 100%; */
}
.blocks2 {
  margin-right: 20px;
  margin-top: 10px;
}
.abs {
  position: absolute;
}
.box {
  padding: 30px 0 0 30px;
  background: white;
}
.prCon {
  display: flex;
  flex-wrap: wrap;
}
.childCon {
  width: 16%;
  margin-bottom: 40px;
}
.childCon2 {
  width: 32%;
  margin-bottom: 40px;
}
.childCon3 {
  width: 100%;
  margin-bottom: 20px;
}
.childCon4 {
  width: 24%;
  margin-bottom: 60px;
}
.childCon5 {
  width: 48%;
  margin-bottom: 60px;
}
.childCon6 {
  width: 24%;
  margin-bottom: 20px;
}
.childCon7 {
  width: 20%;
  margin-bottom: 20px;
}
.childCon8 {
  width: 60%;
  margin-bottom: 20px;
}
.childMt {
  margin-right: 8px;
}
.btnStyle {
  border: 1px solid #409eff;
  color: #409eff;
}
.datePicker {
  width: 256px;
  height: 32px !important;
  line-height: 32px !important;
}
.littleBox input {
  height: 32px !important;
  line-height: 32px !important;
}
::v-deep .el-divider--vertical {
  height: auto;
}
div /deep/ .avue-crud__menu {
  min-height: 0px !important;
}
.el-table {
  width: 100%;
}
.el-table__header-wrapper table,
.el-table__body-wrapper table {
  width: 100% !important;
}
.el-table__body,
.el-table__footer,
.el-table__header {
  table-layout: auto;
}
</style>
